import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import ABOUT from '../img/about.jpg';

class About extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Us"
          keywords={[`EQUE`, `EQUE Mag`, `New Music`, `Playlists` ]}
          image="https://equemag.com/img/eque-share-main.jpg"
        />

        <div className="pages-about content">
          <div className="container">
            <div className="columns ui-grid">
              <div className="column is-6">
                <h2>
                EQUE is a place to discover new music through familiar voices.
                </h2>

                <p>We're a publication that provides artists and creatives a platform to share the music that they believe needs to be heard. Through an integrated mix of playlists and editorials, EQUE gives fans a deep dive into the musical libraries of luminaries along with intimate introductions to new artists.</p>
              </div>
            </div>

            <div className="columns ui-grid aligncenter">
              <div className="column is-10">
                <img src={ABOUT} />
                <p class="caption">Pronounced E•Q</p>
              </div>
            </div>

            <div className="columns ui-grid">
              <div className="column is-6">
                <p>EQUE was founded in Toronto by <a class="text-link" href="https://instagram.com/jumper" target="_blank">Matt Jumper</a>, a multi-disciplinary designer and music photographer, and <a class="text-link" href="https://instagram.com/eon1111" target="_blank">Ian Graham</a>, an entrepreneur and marketer with a background in artist management.</p>
                <p>The mission has always been to put people on the same frequency with music. With that intention, we united under the moniker EQUE – an extraction from the word FREQUENCY.</p>
                <p>Our journey started off in 2016 with creating an app from the ground-up to democratize the music listening experience between friends. We even created a complex algorithm to help find the common music interests that connects everyone.</p>
                <p>In 2020, our focus has narrowed into music discovery. We found that a lot of people – including ourselves – heavily value discovering new music; whether that be uncovering artists they’ve never heard before or deep cuts from artists they’ve known for years. We’ve heard all kinds of ways people discover music, but there was no single place for us to go that was both easily digestible and curated by people we could trust.</p>
                <p>So we made EQUE Mag, a central place for people to discover new music through voices they already know and love. A community of artists and creatives supporting each other and fans connecting to unearth their new favourite sounds.</p>
                <p>Regardless of the medium, we’re always striving to connect people through music. We hope you enjoy what we’re doing with EQUE Mag.</p>
              
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
